import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import Slider from 'src/components/Sites/ServicesPages/shared/Slider';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import Paragraph from 'src/components/Sites/ServicesPages/shared/Paragraph';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import ColoredBox from 'src/components/Sites/ServicesPages/shared/ColoredBox';
import List from 'src/components/shared/List/List';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import { GraphicDesignPageContext } from 'src/pages/projektowanie-graficzne';
import ListItem from 'src/components/shared/List/ListItem/ListItem';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import useLng from "../../../../hooks/useLng";

const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  @media screen and (max-width: 1199px) {
    display: block;
    padding: 0;
  }
`;

const StyledColoredBox = styled(ColoredBox)`
  padding-left: 0;

  @media screen and (max-width: 1199px) {
    padding-left: 7rem;
  }

  @media screen and (max-width: 767px) {
    padding-left: 2rem;
  }
`;

const StyledSubTitle = styled(SubTitle)`
  margin-bottom: 6rem;

  @media screen and (max-width: 767px) {
    margin-bottom: 4rem;
    margin-top: 0;
  }
`;

const StyledSection = styled(Section)`
  @media screen and (max-width: 767px) {
    margin-bottom: 5rem;
  }
`;

const StyledInnerBox = styled(InnerBox)`
  max-width: 80%;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

const VisualIdentificationDesignSection = () => {
  const { data } = useContext(GraphicDesignPageContext);
  const {t} = useLng()
  const kasyno = data?.find(({ name }) => name === 'kasyno');
  const ksiegaIdentyfikacjiWizualnejImg = data?.find(
    ({ name }) => name === 'ksiega identyfikacji wizualnej',
  );

  return (
    <Section>
      <StyledSection as="div">
        <Wrapper nonGrid>
          <FadeIn>
            <Label>{t("graph15")}</Label>
          </FadeIn>
          <FadeIn>
            <Paragraph>
              {t("graph16")}
            </Paragraph>
          </FadeIn>
          <FadeIn>
            <Paragraph>{t("graph17")}
            </Paragraph>
          </FadeIn>
        </Wrapper>
      </StyledSection>
      <StyledWrapper mw>
        <StyledInnerWrapper mw last>
          <StyledSection as="div">
            <FadeIn left>
              <StyledColoredBox>
                <StyledInnerWrapper pl last>
                  <FadeIn animationToProp={{ delay: 1 }}>
                    <StyledSubTitle>
                      {t("graph18")}
                    </StyledSubTitle>
                    <Paragraph small>
                      {t("graph19")}
                    </Paragraph>
                  </FadeIn>
                </StyledInnerWrapper>
              </StyledColoredBox>
            </FadeIn>
          </StyledSection>
          <StyledInnerWrapper pl>
            <InnerBox>
              <FadeIn>
                <StyledSubTitle>
                  {t("graph20")}
                </StyledSubTitle>
              </FadeIn>
              <FadeIn>
                <Paragraph small> {t("graph21")}
                </Paragraph>
              </FadeIn>
            </InnerBox>
            <MoveIn>
              <ImageWrapper
                m="9.7rem 0 8rem"
                image={ksiegaIdentyfikacjiWizualnejImg?.childImageSharp.fluid ?? ""}
                alt="Księga identyfikacji wizualnej"
              />
            </MoveIn>
            <FadeIn>
              <StyledSubTitle>
                {t("graph22")}
              </StyledSubTitle>
            </FadeIn>
            <StyledInnerBox>
              <List>
                <ListItem>
                  <strong>{t("graph23")}</strong> -
                  {t("graph24")}
                    </ListItem>
                <ListItem>
                  <strong>{t("graph25")}</strong> -
                  {t("graph26")}
                </ListItem>
                <ListItem>
                  <strong>{t("graph27")}</strong> -
                  {t("graph28")}
                </ListItem>
                <ListItem>
                  <strong>{t("graph29")}</strong> -
                  {t("graph30")}
                </ListItem>
                <ListItem>
                  <strong>{t("graph31")}</strong> -
                  {t("graph32")}
                </ListItem>
                <ListItem>
                  <strong>{t("graph33")}</strong> -
                  {t("graph34")}
                </ListItem>
              </List>
            </StyledInnerBox>
          </StyledInnerWrapper>
        </StyledInnerWrapper>
        <div>
          <InnerBox bigPadding mw>
            <StyledInnerWrapper mw>
              <FadeIn>
                <StyledSubTitle>
                  {t("graph35")}
                </StyledSubTitle>
              </FadeIn>
              <FadeIn>
                <Paragraph small>
                  {t("graph36")}
                </Paragraph>
              </FadeIn>
              <FadeIn>
                <Paragraph small>
                  {t("graph37")}
                </Paragraph>
              </FadeIn>
            </StyledInnerWrapper>
            <StyledInnerWrapper mw>
              <FadeIn>
                <StyledSubTitle m="10rem 0 6rem">
                  {t("graph38")}
                </StyledSubTitle>
              </FadeIn>
              <FadeIn>
                <Paragraph small>
                  {t("graph39")}
                </Paragraph>
              </FadeIn>
              <FadeIn>
                <Paragraph small>
                  {t("graph40")}
                </Paragraph>
              </FadeIn>
            </StyledInnerWrapper>
          </InnerBox>
          <StyledInnerWrapper mw>
            <MoveIn>
              <ImageWrapper
                m="9.7rem 0 8rem"
                image={kasyno?.childImageSharp.fluid ?? ""}
                alt="Arche"
              />
            </MoveIn>
          </StyledInnerWrapper>
        </div>
      </StyledWrapper>
    </Section>
  );
};

export default VisualIdentificationDesignSection;
