import React from 'react';
import styled, { css } from 'styled-components';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';

const Paragraph = styled(ParagraphInfo)`
  line-height: 1.5;
  font-size: ${({ small }) => (small ? '1.8rem' : '2rem')};
  margin-bottom: 3rem;
  margin: ${({ m }) => m && m};

  &:last-child {
    margin-bottom: 3rem;
  }

  ${({ big }) =>
    big &&
    css`
      font-size: 3rem;
      font-weight: 700;
      margin-bottom: 13.6rem !important;

      @media screen and (max-width: 767px) {
        margin-bottom: 5rem !important;
      }
    `}

  @media screen and (max-width: 767px) {
    margin-bottom: 3rem;
  }
`;

export default Paragraph;
