import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import Slider from 'src/components/Sites/ServicesPages/shared/Slider';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import Paragraph from 'src/components/Sites/ServicesPages/shared/Paragraph';
import { GraphicDesignPageContext } from 'src/pages/projektowanie-graficzne';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import useLng from "../../../../hooks/useLng";

const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  margin-bottom: ${({ mb }) => mb && mb};

  @media screen and (max-width: 1199px) {
    display: block;
    padding: 0;
    margin-bottom: 5rem;
  }
`;

const SubTitleWrapper = styled.div`
  margin: 6rem 0;

  ${SubTitle} {
    margin: 0;
  }

  @media screen and (max-width: 767px) {
    margin: 4rem 0;
  }
`;

const VisualIdentificationSection = () => {
  const { data } = useContext(GraphicDesignPageContext);

  const identyfikacjaWizualna1 = data?.find(
    ({ name }) => name === 'czym jest identyfikacja wizualna',
  );
  const identyfikacjaWizualna2 = data?.find(
    ({ name }) => name === 'czym jest identyfikacja wizualna 2',
  );
  const theAssist = data?.find(({ name }) => name === 'assist');
  const werandaFamily = data?.find(({ name }) => name === 'weranda family');
const {t} = useLng()
  return (
    <Section>
      <Wrapper pl nonGrid>
        <FadeIn>
          <SubTitle m="0">{t("graph4")}</SubTitle>
        </FadeIn>
      </Wrapper>
      <StyledWrapper mw>
        <div>
          <StyledInnerWrapper pl mb="7rem">
            <FadeIn>
              <Paragraph>
                {t("graph5")}
              </Paragraph>
            </FadeIn>
          </StyledInnerWrapper>
          <StyledInnerWrapper mw>
            <MoveIn>
              <Slider
                slides={[identyfikacjaWizualna1, identyfikacjaWizualna2]}
              />
            </MoveIn>
          </StyledInnerWrapper>
        </div>
        <InnerBox pl mw>
          <FadeIn>
            <Paragraph>
              {t("graph6")}
            </Paragraph>
          </FadeIn>
          <SubTitleWrapper>
            <FadeIn>
              <SubTitle>
                {t("graph7")}
              </SubTitle>
            </FadeIn>
          </SubTitleWrapper>
          <FadeIn>
            <Paragraph>
              {t("graph8")}
            </Paragraph>
          </FadeIn>
        </InnerBox>
      </StyledWrapper>
      <Wrapper nonGrid>
        <FadeIn up>
          <Paragraph big>
            {t("graph9")}
          </Paragraph>
        </FadeIn>
      </Wrapper>
      <StyledWrapper mw>
        <div>
          <StyledInnerWrapper pl>
            <FadeIn>
              <Paragraph small>
                {t("graph10")}
              </Paragraph>
            </FadeIn>
          </StyledInnerWrapper>
          <StyledInnerWrapper mw>
            <MoveIn>
              <Slider slides={[theAssist, werandaFamily]} />
            </MoveIn>
          </StyledInnerWrapper>
        </div>
        <InnerBox pl mw>
          <FadeIn>
            <SubTitle>{t("graph11")}</SubTitle>
          </FadeIn>
          <FadeIn>
            <Paragraph small>
              {t("graph12")}
            </Paragraph>
          </FadeIn>
          <FadeIn>
            <Paragraph small>
              {t("graph13")}
            </Paragraph>
          </FadeIn>
          <FadeIn>
            <Paragraph small>
              {t("graph14")}
            </Paragraph>
          </FadeIn>
        </InnerBox>
      </StyledWrapper>
    </Section>
  );
};

export default VisualIdentificationSection;
