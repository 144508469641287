import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import Paragraph from 'src/components/Sites/ServicesPages/shared/Paragraph';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import Slider from 'src/components/Sites/ServicesPages/shared/Slider';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import { GraphicDesignPageContext } from 'src/pages/projektowanie-graficzne';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import useLng from "src/hooks/useLng"
const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  @media screen and (max-width: 1199px) {
    display: block;
    padding: 0;
  }
`;

const StyledSection = styled(Section)`
  @media screen and (max-width: 767px) {
    margin-bottom: 5rem;
  }
`;

const VisualIdentificationPricesSection = () => {
  const { data } = useContext(GraphicDesignPageContext);
  const {t} = useLng();
  const slide1 = data?.find(
    ({ name }) => name === 'kanapa na drewnianych nogach',
  );

  const slide2 = data?.find(({ name }) => name === 'wizualizacje 3d');

  return (
    <Section>
      <StyledSection as="div">
        <Wrapper nonGrid>
          <FadeIn>
            <Label>
              {t("graph41")}
            </Label>
          </FadeIn>
          <FadeIn>
            <Paragraph>
              {t("graph42")}
            </Paragraph>
          </FadeIn>
        </Wrapper>
      </StyledSection>
      <StyledWrapper mw>
        <StyledInnerWrapper pl>
          <FadeIn>
            <SubTitle>
              {t("graph43")}
            </SubTitle>
          </FadeIn>
          <InnerBox>
            <FadeIn>
              <Paragraph small>
                {t("graph44")}
              </Paragraph>
            </FadeIn>
          </InnerBox>
        </StyledInnerWrapper>
        <MoveIn>
          <Slider left slides={[slide1, slide2]} />
        </MoveIn>
      </StyledWrapper>
    </Section>
  );
};

export default VisualIdentificationPricesSection;
