import React, { createContext } from 'react';
import styled from 'styled-components';
import SEO from 'src/components/seo';
import  Layout  from 'src/layouts/Layout';
import SocialMedia from 'src/components/SocialMedia/SocialMedia';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import {
  Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import {
  HeaderParagraph,
  HeaderSection,
  HeaderTextWrapper,
  HeaderTitle,
} from 'src/components/Sites/ServicesPages/shared/Header';
import VisualIdentificationSection from 'src/components/Sites/ServicesPages/GraphicDesign/VisualIdentificationSection';
import VisualIdentificationDesignSection from 'src/components/Sites/ServicesPages/GraphicDesign/VisualIdentificationDesignSection';
import VisualIdentificationPricesSection from 'src/components/Sites/ServicesPages/GraphicDesign/VisualIdentificationPricesSection';
import { graphql } from 'gatsby';
import FadeLink from 'src/components/shared/navigations/FadeLink/FadeLink';
import useLng from "src/hooks/useLng";

export const GraphicDesignPageContext = createContext({});

const StyledHeader = styled(HeaderSection)`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledImageWrapper = styled(ImageWrapper)`
  max-width: 50vw;
  width: 100%;
  flex-shrink: 0;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

const StyledHeaderSection = styled(Section)`
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
`;

const StyledSocialMedia = styled(SocialMedia)`
  position: absolute;
  left: 5rem;
  top: 7rem;
  padding: 4rem 7rem 0 0;

  @media screen and (max-width: 1199px) {
    left: 3rem;
  }

  @media screen and (max-width: 991px) {
    left: 2rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledFadeLink = styled(FadeLink)`
  font-size: 2.2rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const GraphicDesign = ({
  location: { pathname: path },
  transitionStatus,
  entry,
  exit,
  data,
}) => {
  const headingImage = data.allFile.nodes.find(
    ({ name }) => name === 'obraz z symbolem projektowania graficznego',
  ).childImageSharp.fluid;

  const {t} = useLng();

  return (
    <Layout
      path={path}
      id="projektowanie-graficzne"
      nonBgc
      transitionStatus={transitionStatus}
      entry={entry}
      exit={exit}
    >
      <SEO title={t("graphSeo")} />
      <StyledHeaderSection nonGrid site first>
        <StyledHeader pl>
          <HeaderTextWrapper>
            <StyledSocialMedia />
            <div>
              <HeaderTitle small>
                {t("graph")}
              </HeaderTitle>
              <HeaderParagraph big>
                {t("graph1")}
              </HeaderParagraph>
              <HeaderParagraph>
                {t("graph3")}
              </HeaderParagraph>
            </div>
          </HeaderTextWrapper>
          <StyledImageWrapper
            image={headingImage}
            alt="obraz z symbolem projektowania graficznego"
          />
        </StyledHeader>
      </StyledHeaderSection>
      <GraphicDesignPageContext.Provider value={{ data: data.allFile.nodes }}>
        <>
          <VisualIdentificationSection />
          <VisualIdentificationDesignSection />
          <VisualIdentificationPricesSection />
        </>
      </GraphicDesignPageContext.Provider>
      <Wrapper nonGrid>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <StyledFadeLink to="/projekty" stylish>
            {t("zobaczRealizacje")}
          </StyledFadeLink>
        </div>
      </Wrapper>
    </Layout>
  );
};

export const graphicDesignQuery = graphql`
  query GraphicDesignQuery {
    allFile(filter: { relativeDirectory: { eq: "graphic-design" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export default GraphicDesign;
